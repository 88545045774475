<template>
  <div class="container">
    <a-row>
      <a-col :span="8">
        <div>Current Price</div>
        <a-select placeholder="Select map" v-model="currentMap" style="width: 200px" @change="getCurrentPrice">
          <a-select-option value="1">
            Map 1
          </a-select-option>
          <a-select-option value="2">
            Map 2
          </a-select-option>
          <a-select-option value="3">
            Map 3
          </a-select-option>
          <a-select-option value="4">
            Map 4
          </a-select-option>
          <a-select-option value="5">
            Map 5
          </a-select-option>
          <a-select-option value="6">
            Map 6
          </a-select-option>
        </a-select>
      </a-col>
      <a-col :span="8">
        <div>Current Price</div>
        <a-input placeholder="Current Price" v-model="currentPriceByMap" disabled style="width: 80%"/>
      </a-col>
      <a-col :span="8">
        <div>New Price</div>
        <a-input v-model="newPrice" placeholder="New Price" style="width: 80%"/>
        <a-button class="btn" type="primary" @click="submit">
          Submit
        </a-button>
      </a-col>
    </a-row>

  </div>
</template>
<script>
export default {
  name: 'UpdatePriceNFT',
  data () {
    return {
      currentMap: null,
      newPrice: ''
    }
  },
  computed: {
    currentPriceByMap () {
      return this.$store.getters.currentPriceByMap
    }
  },
  methods: {
    getCurrentPrice () {
      this.newPrice = ''
      this.$store.dispatch('getPrice', this.currentMap)
    },
    async submit () {
      if (this.newPrice && this.newPrice.trim('') !== '') {
      const _rs = await this.$store.dispatch('setPriceByMap', {
          map: this.currentMap,
          price: this.newPrice
        })
      if (_rs.code === 1) {
        this.$message.info('Update Success');
      }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .container {
    width: 80%;
    margin: 20% auto;
  }
  .btn {
    margin: 20px auto;
  }
</style>